// Docket Alarm "common" javascript library. Code that is generally used across all page.
// TODO: Split libraries up into smaller pieces, and export individually.
import * as common from "../site_media/common.js";
window.common = common;
import * as common_billing from "./common_billing.js";
window.common_billing = common_billing;

// matter input widget

// import * as pleadingtags from "../site_media/pleading_tags.js";
